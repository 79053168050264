export const names = [
    {
      game: 'Scarlet',
      generationNumber: 7
    },
    {
      game: 'Violet',
      generationNumber: 7
    },
    {
      game: 'Legends: Arceus',
      generationNumber: 6
    },
    {
      game: 'Sword',
      generationNumber: 5
    },
    {
      game: 'Shield',
      generationNumber: 5
    },
    {
      game: "Let's Go, Pikachu",
      generationNumber: 4
    },
    {
      game: "Let's Go, Eevee",
      generationNumber: 4
    },
    {
      game: 'Ultra Sun',
      generationNumber: 3
    },
    {
      game: 'Ultra Moon',
      generationNumber: 3
    },
    {
      game: 'Sun',
      generationNumber: 2
    },
    {
      game: 'Moon',
      generationNumber: 2
    },
    {
      game: 'OMEGA Ruby',
      generationNumber: 1
    },
    {
      game: 'ALPHA Sapphire',
      generationNumber: 1
    },
  ];